import React from "react";

// Base song data without lyrics
export const songs = [
  {
    title: "ChemTrails",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1581452697/chaoticorder-feedthepeople/full-images/Chemtrails_uvyaam.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593741/chaoticorder-feedthepeople/1-Chemtrails_vvfljp.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/Chemtrails_uvyaam.jpg",
  },
  {
    title: "Feed The People",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1581452697/chaoticorder-feedthepeople/full-images/album-cover-2500x1364-FEEDTHEPEOPLE_p3piu5.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593751/chaoticorder-feedthepeople/2-FeedThePeople_r5wbxp.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/album-cover-2500x1364-FEEDTHEPEOPLE_p3piu5.jpg",
  },
  {
    title: "Burn",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1581452698/chaoticorder-feedthepeople/full-images/The-Tower-BURN_v3zngp.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593780/chaoticorder-feedthepeople/3-BURNIT_wq3s6h.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452698/chaoticorder-feedthepeople/full-images/The-Tower-BURN_v3zngp.jpg",
  },
  {
    title: "Another Thing",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1581452698/chaoticorder-feedthepeople/full-images/The-Wait-ANOTHERTHING_wgkuet.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593742/chaoticorder-feedthepeople/4-AnotherThing_qrfsn3.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452698/chaoticorder-feedthepeople/full-images/The-Wait-ANOTHERTHING_wgkuet.jpg",
  },
  {
    title: "Hate",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452698/chaoticorder-feedthepeople/full-images/the-oracle-HATE_fn2hvj.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593746/chaoticorder-feedthepeople/5-HATEREPULSION_s8owm1.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452698/chaoticorder-feedthepeople/full-images/the-oracle-HATE_fn2hvj.jpg",
  },
  {
    title: "Sorry",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/closeyoureyestosee-SORRY_ev76he.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593760/chaoticorder-feedthepeople/6-Sorry_s9hoyu.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/closeyoureyestosee-SORRY_ev76he.jpg",
  },
  {
    title: "Chains",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/medicineman-CHAINS_p19xji.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593763/chaoticorder-feedthepeople/7-CHAINS_yiqxlf.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/medicineman-CHAINS_p19xji.jpg",
  },
  {
    title: "Earth",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/HighPriestess-EARTH_bcy5r9.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593759/chaoticorder-feedthepeople/8-ChaoticOrder_q0zas1.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/HighPriestess-EARTH_bcy5r9.jpg",
  },
  {
    title: "Never End",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452698/chaoticorder-feedthepeople/full-images/The-Magician-NEVEREND_mpmzxc.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593764/chaoticorder-feedthepeople/9-NeverEnd_vlxoad.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452698/chaoticorder-feedthepeople/full-images/The-Magician-NEVEREND_mpmzxc.jpg",
  },
  {
    title: "Colors",
    singer: "Chaotic Order",
    thumbnail: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/shaman-COLORS_a9vvpy.jpg",
    audio: "https://res.cloudinary.com/dexdumfqy/video/upload/v1579593781/chaoticorder-feedthepeople/10-Colors_db6xqj.mp3",
    picture: "https://res.cloudinary.com/dexdumfqy/image/upload/v1581452697/chaoticorder-feedthepeople/full-images/shaman-COLORS_a9vvpy.jpg",
  },
];

// Lyrics mapping by song title
export const lyrics = {
  "ChemTrails": [
    <div>
      <p>
        Hay hay That's a big old plane
        <br />
        White lines in the sky, Making me wonder
        <br />
        Hay hay Take the planes away
        <br />
        They dirty the sky, Making it Hotter
      </p>

      <p>
        Big O cans flying through the sky
        <br />
        Sure know why it's on my mind
        <br />
        We talk about a better way
        <br />
        All the lines all through the sky
      </p>
      <br />
      <p>
        When it all comes down to faith
        <br />
        There's nothing there that's good for you
        <br />
        They say the lines goanna make it rain
        <br />
        It's only a matter of time no lie
      </p>
      <br />
      <p>chem trails</p>
      <br />
      <p>
        {" "}
        Protecting us from bad sun rays
        <br />
        Others say it's such a haze
        <br />
        Carbon dioxide from the planes
        <br />
        Soot particulates are to blame
      </p>
      <br />
      <p>
        When it all comes down to faith
        <br />
        There's nothing there that's good for you
        <br />
        They say the lines goanna make it rain
        <br />
        It's only a matter of time no lie
      </p>
      <br />
      <p>Chem Trails </p>
    </div>
  ],
  "Feed The People": [
    <div>
      <p>
        Hayeeeeee
        <br />
        Feed the people inside your life
        <br />
        Feed the people just get in line
        <br />
        Feed the people another lie
        <br />
        Feed the people open wide
      </p>

      <br />
      <p>
        Uuuu uou, Every time we try to get ahead
        <br />
        Feed the People
        <br />
        Feed the people
        <br />
        Uuuu uou,
        <br />
        We give you take it's all that we can stand
        <br />
        Feed the People
        <br />
        Feed the People
        <br />
        Uuuu uou
      </p>

      <p>
        Feed the people almighty dollar
        <br />
        Feed the people ain't worth a dime
        <br />
        Feed the people whole pack of lies
        <br />
        Feed the people just one more time
      </p>

      <br />
      <p>
        Uuuu uou Every time we try to get ahead
        <br />
        Feed the People
        <br />
        Feed the people
        <br />
        Uuuu uou
      </p>

      <br />

      <p>
        We give you take it's more than we can stand
        <br />
        Feed the People
        <br />
        Feed the People
        <br />
        Uuuuuou
      </p>

      <p>
        Feed the people insecticides
        <br />
        Feed the people the pesticides
        <br />
        Feed the people your dirty water
        <br />
        Feed the people there hungry
      </p>
      <br />
      <p>
        Uuuu uou
        <br />
        Every time we try to get ahead
        <br />
        Feed the People
        <br />
        Feed the people
      </p>
      <br />
      <p>
        Uuuu uou
        <br />
        We give you take it's all that we can stand
        <br />
        Feed the People
        <br />
        Feed the People
      </p>
      <br />

      <p>
        Feed the people insecticides
        <br />
        Feed the people the pesticides
        <br />
        Feed the people your dirty water
        <br />
        Feed the people we're hungry
      </p>
    </div>
  ],
  "Burn": [
    <div>
      <p>
        Hey Hey Hey
        <br />
        Burn it
        <br />
        Burn it
        <br />
        Burn it to the ground{" "}
      </p>
      <br />

      <p>
        Ashes -blowing in the wind
        <br />
        Smoke -burns through your eye's
        <br />
        Dust -all through the air
        <br />
        Darkness -fall's through the sky
      </p>
      <br />

      <p>
        Burn it - Look around
        <br />
        Burn it - It's falling down
        <br />
        Burn it. -To the ground
        <br />
        Burn it -We will all be Falling down
      </p>
      <br />

      <p>
        Axes -Will lose controls
        <br />
        Heat -Will over load
        <br />
        Molten -Rocks do explode,
        <br />
        Then rumble beneath your-feet
      </p>

      <br />

      <p>
        Burn it -Plumes of smoke
        <br />
        Burn it -Until we choke
        <br />
        Burn it -There Nothing left
        <br />
        Burn it. -We will burn it to the ground
      </p>
      <br />

      <p>
        We will burn it to the ground
        <br />
        We will burn it to the ground
      </p>
    </div>
  ],
  "Another Thing": [
    <div>
      <p>
        You get a piece of me
        <br />
        ya taste so fine
        <br />
        Yes I like to hold you down
      </p>
      <br />

      <p>
        As you move I turn
        <br />
        Right on time
        <br />O so fine
      </p>
      <br />

      <p>
        Shhhhhh
        <br />
        <br />
        Don't say a word
        <br />
        Harder never deep enough
        <br />
        Shiver with delight
        <br />
        Rocking it through the night
      </p>
      <br />

      <p>
        Don't say a word
        <br />
        Harder never deep enough
        <br />
        Shiver with delight
        <br />
        Rocking it through the night
      </p>

      <p>
        It's not what we know
        <br />
        It's who we know
        <br />
        We thought it was good
        <br />
        So here's what I got to say
      </p>
      <br />

      <p>
        Oooooo
        <br />
        <br />
        Try to confuse me
        <br />
        In this state of mine
        <br />
        Try to abuse me
        <br />
        So easy you defy
      </p>
      <br />

      <p>
        Try to change my world
        <br />
        I blink you lie
        <br />
        Yes same o rumor in town
        <br />
        As you move on out
        <br />
        Right on time
        <br />O not again
      </p>
      <br />

      <p>
        Shhhhhh
        <br />
        <br />
        Don't say a word
        <br />
        Hammer beating in my
        <br />
        Shiver to the bone
        <br />
        Were always good in bed
      </p>
      <br />

      <p>
        Another thing I must confess
        <br />
        You're still a taker who never gives
        <br />
        This won't happen
        <br />
        Ever again
      </p>
      <br />

      <p>
        Always a lie
        <br />
        You always deny
      </p>
      <br />

      <p>
        Try to confuse me
        <br />
        In this state of mine
        <br />
        Try to abuse me
        <br />
        So easily defy
      </p>
      <br />

      <p>
        You're a taker who never gives back
        <br />
        Difficult and exhausting
        <br />
        I'm over it...
      </p>
      <br />
    </div>
  ],
  "Hate": [
    <div>
      <p>
        Hayyyyyyyyyy
        <br />
        <br />
        Hate is my best friend
        <br />
        Hate is what I feel for you
        <br />
        Fear is what you see in me
        <br />
        Pain I have in store for you
      </p>
      <br />

      <p>
        Leave me alone
        <br />
        Let me be
        <br />
        Let me go
      </p>
      <br />

      <p>
        Is this what you want me to see
        <br />
        Is this what you wanted me to be
        <br />
        Look at what you've done to me
        <br />
        What are you doing to me
        <br />
        I want you to leave me alone
        <br />
        Get back.....
      </p>
      <br />

      <p>Haaaaa</p>
      <br />

      <p>
        Hate is my best friend
        <br />
        Hate is my best friend who's lonely
        <br />
        I hate to be the only one
        <br />
        Don't you know that I'm living in hell
      </p>
      <br />

      <p>
        Is this what you wanted to see
        <br />
        <br />
        Is this what you want me to be
      </p>
      <br />

      <p>
        I don't understand
        <br />
        Why don't you let me out
      </p>
      <br />

      <p>
        Ask your mother while you're freaking me out
        <br />
        I'll find a way out to cure this disease
        <br />
        I've never seen the man on the moon
      </p>
      <br />

      <p>ha ha ha ha</p>
      <br />

      <p>
        I hate you
        <br />
        Leave me alone
        <br />
        I hate that you hate
        <br />
        What we want to be
        <br />
        Is this what you wanted to see
        <br />
        Is this what you want me to be
      </p>
      <br />

      <p>
        LEAVE ME ALONE
        <br />
        <br />
        You're alone in the dark,
        <br />
        ha ha ha ha ha
      </p>
      <br />
    </div>
  ],
  "Sorry": [
    <div>
      <p>Uuummm aaaaaaa</p>
      <br />

      <p>I'm so sorry Ya I didn't mean to make you go away</p>
      <br />

      <p>I'm so sorry For putting you ten feet Under ground</p>
      <br />

      <p>
        Sorry I'm sorry you've gone away
        <br />
        <br />
        Sorry Didn't mean to make you go
      </p>
      <br />

      <p>Down into the ground I put you there</p>
      <br />

      <p>Since you've gone away I miss your soul</p>
      <br />

      <p>Sorry Down into the ground</p>
      <br />

      <p>Sorry I didn't mean to let you go</p>
      <br />

      <p>Mmmmmmmm The grass is always greener Where I know you are</p>
      <br />
    </div>
  ],
  "Chains": [
    <div>
      <p>
        Chains
        <br />
        Chains
        <br />
        <br />
        Nogoooo
      </p>
      <br />

      <p>
        Bang my head a way I don't want to be
        <br />
        Don't know why you take me there
        <br />
        Twist and turn my insecurity
        <br />
        Do you like to watch me squirm
      </p>
      <br />

      <p>
        Shut up
        <br />
        Shut up and leave me be
        <br />
        There's no pleasing you
        <br />
        All the pain I grieve
      </p>
      <br />

      <p>
        Why do I endure your shame
        <br />
        Breaking through - through all these chains
        <br />
        Remove the shackles of my pain
        <br />
        Breaking through-through all these chains
      </p>
      <br />

      <p>All the chains</p>
      <br />

      <p>
        What you do is insanity
        <br />
        Insane to me
      </p>
      <br />

      <p>
        The fear inside me
        <br />
        Is suspicious
        <br />
        Suspicious
      </p>
      <br />

      <p>
        Are you pleased with your devise
        <br />
        Devise
      </p>
      <br />

      <p>
        Or will you leave
        <br />
        As I lay dying
      </p>
      <br />

      <p>
        Shut up
        <br />
        Shut up and leave me be
        <br />
        There's no pleasing you
        <br />
        All the pain I've grieve
      </p>
      <br />

      <p>Nogooo</p>
      <br />

      <p>
        All the pain I greave
        <br />
        Breaking through the chains
      </p>
      <br />

      <p>
        Why do I endure your shame
        <br />
        Breaking through - through all these chains
        <br />
        Remove the shackles of my pain
      </p>
      <br />

      <p>
        All the chains
        <br />
        All the Pain
        <br />
        All the chains
        <br />
        All the Pain—
        <br />
        All the chains
        <br />
        All the chains
        <br />
        All the chains
        <br />
        All the chains
      </p>
      <br />

      <p>
        Nogoooo
        <br />
        <br />
        All the pain I grieve
        <br />
        Breaking through the chains
        <br />
        All the pain I grieve
        <br />
        Breaking through the chains
        <br />
        Chains
        <br />
        Chains
        <br />
        Chains
        <br />
        Pain I greave
        <br />
        Chains
        <br />
        Through the chains
      </p>
      <br />
    </div>
  ],
  "Earth": [
    <div>
      <p>
        Who am I
        <br />
        Who brings you light
        <br />
        Through the darkness Of the night
      </p>
      <br />

      <p>Oooooo</p>
      <br />

      <p>
        Who am I
        <br />
        What are we
        <br />
        Where do we go
        <br />
        Why do we live
        <br />
        How much longer
        <br />
        For the eeeeeeearth
      </p>
      <br />

      <p>
        Who am I
        <br />
        Who brings you light
        <br />
        Through the darkness Of the night
      </p>
      <br />

      <p>
        Aaaaaa Aaaaaa Aaaa
        <br />
        <br />
        WE WILL RETURN
      </p>
      <br />

      <p>
        Through the night
        <br />
        Black or white
        <br />
        Makes no difference
      </p>
      <br />

      <p>
        Soooooooo
        <br />
        <br />
        Without the light
      </p>
      <br />

      <p>
        Who am I
        <br />
        What are we
        <br />
        Where do we go
        <br />
        Why do we live
        <br />
        How much longer
        <br />
        For the eeeeearth
      </p>
      <br />

      <p>
        On the earth
        <br />
        Do the good
        <br />
        Aand good will be Until the end of end
      </p>
      <br />

      <p>
        Aaaaaa Aaaaaa Aaaa
        <br />
        <br />
        HuuRrrraaaaaaaaaa
      </p>
      <br />
    </div>
  ],
  "Never End": [
    <div>
      <p>Tik Tik Tik</p>
      <br />

      <p>
        Take me to another place
        <br />
        Past the stars that never end
        <br />
        Stopping time all hands stand still.
        <br />
        Never end this endless dream
      </p>
      <br />

      <p>Let's rooooooooooll</p>
      <br />

      <p>
        Holding the hands of time
        <br />
        Bleeding through your sole
        <br />
        As we fade away
        <br />
        Never to return
        <br />
        <br />
        Never to return
      </p>
      <br />

      <p>
        Through the darkest night
        <br />
        Wake the spirit light
        <br />
        Something there you cannot see
        <br />
        Time that never ends
        <br />
        Time that never ends
        <br />
        Never ends
      </p>
      <br />

      <p>
        Holding the hands of time
        <br />
        Bleeding through your sole.
        <br />
        And as we fade away
        <br />
        Never to return
      </p>
      <br />

      <p>
        Never to return
        <br />
        Never to return
      </p>
      <br />

      <p>Let's rooooooooll</p>
      <br />

      <p>
        Holding the hands of time
        <br />
        Bleeding through your sole
        <br />
        As we fade away
        <br />
        Never to return
        <br />
        <br />
        Never to return...
      </p>
      <br />
    </div>
  ],
  "Colors": [
    <div>
      <p>
        I'm not an angel
        <br />
        I never said I was
        <br />
        Some things are best left unsaid
        <br />
        Halfway through my life
        <br />
        I'm still searching on
        <br />
        For something better than yesterday
      </p>
      <br />

      <p>Mmmmmmmmm</p>
      <br />

      <p>
        Pathway through our lives
        <br />
        Youth were searching for
        <br />
        Never knowing what you'll find
      </p>
      <br />

      <p>
        If love could've saved us you'd still be here
        <br />
        You're the life of the party Uninvited
        <br />
        Stop preaching to the preacher we've all been around the block
        <br />
        It's not our first rodeo
        <br />
        how far can we go
      </p>
      <br />

      <p>Red Yellow Green Blue are the Colors of our soles</p>
      <br />

      <p>
        Life lives in memories from the sunrise to the sun set
        <br />
        Does it matter after all we're do we go from here
        <br />
        Can you really see -
        <br />
        See where you're going to
      </p>
      <br />

      <p>
        Walking to the edge of the earth to the top of the ice
        <br />
        As it starts to crack slipping I'm falling down
        <br />
        Better way of life
        <br />
        In my life I'm nobody
        <br />
        Cause I can't spend it with you
      </p>
      <br />

      <p>
        What goes around comes around as it reaches through your soul
        <br />
        <br />
        What you've done will come back to you
        <br />
        <br />
        What you've done will surely be there
        <br />
        <br />
        What goes around is what you reap in us all
      </p>
      <br />

      <p>
        What goes around
        <br />
        <br />
        Will come back to you
        <br />
        Aaaaaaaa
      </p>
      <br />
    </div>
  ],
};

// Helper function to get songs with lyrics
export const getSongsWithLyrics = () => {
  return songs.map(song => ({
    ...song,
    lyric: lyrics[song.title] || []
  }));
};

// For backwards compatibility
export const songsDesktop = getSongsWithLyrics();
export const songsMobile = songs;

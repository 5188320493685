import React, { lazy, Suspense } from "react";
import "./css/main.css";

import Header from "./components/s1Header/s1Header";
import FeedThePeople from "./components/s2FeedThePeople/s2FeedThePeople";
import GlobalLightingProject from "./components/s4GlobalLightingProject/s4GlobalLightingProject";

import Embed from "react-song-embed";
import { Helmet } from "react-helmet";
import "./css/textstyles.css";
import SEO from "./components/common/SEO";
import LoadingSpinner from "./components/common/LoadingSpinner";

//import Bio from "./components/s3Bio/s3Bio";
const Bio = lazy(() => import("./components/s3Bio/s3Bio"));

//import Contact from "./components/s5FooterContact/s5FooterContact";
const Contact = lazy(() =>
  import("./components/s5FooterContact/s5FooterContact")
);

const Home = () => (
  <React.Fragment>
    <SEO
      title="Chaotic Order Music: Call of Death - Coming Soon!"
      description="Chaotic Order - San Diego's premier metal band bringing you hard-hitting original music. Stream our albums now and watch for our upcoming release, Call of Death coming soon!"
      image="https://chaoticordermusic.com/logo512.png"
      imageAlt="Feed The People Album Cover"
      url="https://chaoticordermusic.com"
      keywords="Chaotic Order, metal band, San Diego metal, Call of Death album, Feed The People album, heavy metal music"
    />
    <Helmet htmlAttributes={{ lang: "en" }}>
      {/* Only include performance/resource hints */}
      <link rel="preconnect" href="https://album.link" crossOrigin />
      <link rel="dns-prefetch" href="https://album.link" />
      <link rel="preconnect" href="https://res.cloudinary.com" crossOrigin />
      <link rel="dns-prefetch" href="https://res.cloudinary.com" />
      <link rel="preconnect" href="https://odesli.co" crossOrigin />
      <link rel="dns-prefetch" href="https://odesli.co" />
      <link rel="preconnect" href="https://embed.song.link" crossOrigin />
      <link rel="dns-prefetch" href="https://embed.song.link" />
      <link rel="preconnect" href="https://fonts.googleapis.com" crossOrigin />
      <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
        rel="stylesheet"
        media="print"
        onLoad="this.media='all'"
      />
      <link
        rel="preload"
        href="/static/media/DEATH_FONT.b5b1db9a.woff2"
        as="font"
        type="font/woff2"
        crossorigin="anonymous"
      />
      {/* Add font-display style for custom font */}
      <style>
        {`
          @font-face {
            font-family: 'DeathFont';
            src: url('/static/media/DEATH_FONT.b5b1db9a.woff2') format('woff2');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
        `}
      </style>
    </Helmet>

    <div className="main">
      <div id="wrapper">
        <Embed
          samesite="none"
          url="https://album.link/VdPNv59kZRQM8"
          height={52}
          dark
          style={{
            display: "fixed",
            position: "relative",
            zIndex: "20",
          }}
        />
        <div id="main">
          <div className="inner">
            <section
              id="home-section"
              style={{
                display: "flex",
                flexDirection: "column",
                /*width: "100%",
                height: "auto"*/
              }}
            >
              <Header />

              <FeedThePeople />
              <Suspense
                fallback={<LoadingSpinner message="Loading bio section..." />}
              >
                <Bio />
              </Suspense>

              <GlobalLightingProject />

              <Suspense
                fallback={
                  <LoadingSpinner message="Loading contact section..." />
                }
              >
                <Contact />
              </Suspense>

              {/*<ContainerFooter/>*/}
            </section>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default Home;

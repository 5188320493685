import React from "react";
import { TaggedContentCard } from "react-ui-cards";
import { FaPlay } from "react-icons/fa";
//import { Col } from "reactstrap";

const SongCard = ({ song, onClick }) => {
  return (
    <div >
      <TaggedContentCard
        className="plzwork"
        style={{ width: "20px", minWidth: "175px", boxShadow: "3px 2px 6px black", }}
        thumbnail={song.picture}
        title={song.title}
        description=""
        tags={[<FaPlay focusable="false" aria-hidden="true" />]}
        onClick={onClick}
      />
    </div>
  );
};

export default SongCard;

import React from "react";
import { Helmet } from "react-helmet";
//import PropTypes from "prop-types";

const SEO = () => {
//  title,
//  description,
//  image,
//  imageAlt,
//  url,
//  keywords,
//  type = "website",
//  twitterCard,
  // Default site values
 // const defaults = {
 //   title: "Chaotic Order Music",
 //   description:
 //     "Chaotic Order - San Diego's premier metal band bringing you hard-hitting original music.",
 //   image: "https://chaoticordermusic.com/logo512.png",
 //   imageAlt: "Chaotic Order Logo",
 //   url: "https://chaoticordermusic.com",
 //   keywords: "Chaotic Order, metal band, San Diego metal",
 //   twitterSite: "@ChaoticOrder",
 //   twitterCreator: "@ChaoticOrder",
 // };

/*  const seo = {
    title: title || defaults.title,
    description: description || defaults.description,
    image: image || defaults.image,
    imageAlt: imageAlt || defaults.imageAlt,
    url: url || defaults.url,
    keywords: keywords || defaults.keywords,
    type: type,
    twitterCard: twitterCard || defaults.image,
  };
*/
  return (
    <Helmet>
      {/* Basic SEO */}
  {/*    <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <link rel="canonical" href={seo.url} />
  */}
      {/* OpenGraph tags */}
  {/*    <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.imageAlt} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:site_name" content={defaults.title} />
      <meta property="og:locale" content="en_US" />
    */}
      {/* Twitter Card tags */}
  {/* <meta name="twitter:card" content={seo.twitterCard} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:site" content={defaults.twitterSite} />
      <meta name="twitter:creator" content={defaults.twitterCreator} />
   */}
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "MusicGroup",
          name: "Chaotic Order",
          url: "https://chaoticordermusic.com",
          image: "https://chaoticordermusic.com/logo512.png",
          description:
            "San Diego's premier metal band bringing you hard-hitting original music",
          // Add more structured data as appropriate
          sameAs: [
            "https://www.facebook.com/ChaoticOrder",
            "https://twitter.com/ChaoticOrder",
            "https://www.instagram.com/ChaoticOrder",
          ],
          member: [
            {
              "@type": "Person",
              name: "Jack Dubord",
              url: "https://chaoticordermusic.com/#jack-dubord",
              image:
                "https://chaoticordermusic.com/static/media/jackscave-left.3e34624e.jpg",
              description: "Lead Guitarist and Vocalist",
            },
          ],
          album: [
            {
              "@type": "MusicAlbum",
              name: "Feed The People",
              url: "https://chaoticordermusic.com/#FeedThePeople",
              image:
                "https://chaoticordermusic.com/static/media/album-cover2.58aa423c.jpg",
              description: "Feed The People",
              byArtist: {
                "@type": "MusicGroup",
                name: "Chaotic Order",
              },
            },
            {
              "@type": "MusicAlbum",
              name: "Global Lighting Project",
              url: "https://chaoticordermusic.com/#GlobalLightingProject",
              image:
                "https://chaoticordermusic.com/static/media/cdcover-glp.f89e60fb.jpg",
              description: "Global Lighting Project",
              byArtist: {
                "@type": "MusicGroup",
                name: "Chaotic Order",
              },
            },
          ],
        })}
      </script>
    </Helmet>
  );
};


export default SEO;

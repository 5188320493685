import React from "react";
import PropTypes from 'prop-types';
import Container from '../common/container';
import AlbumDisplay from './AlbumDisplay';
import "./s1Header.css";

import Logo from "../../images/logo-tan-large-10-fs8.png";
import LogoMobile from "../../images/logo-tan-column-sm-fs8.png";


const Header = ({ albumTitle = "Call of Death" }) => {
  const handleImageError = (e) => {
    e.target.style.display = 'none';
    console.error('Image failed to load:', e.target.src);
  };

  return (
    <Container 
      id="containerHeader"
      scrollId="one"
      scrollBehavior="center"
      className="default"
    >
      <AlbumDisplay
        albumCover=""
        logo={Logo}
        logoMobile={LogoMobile}
        title={albumTitle}
        onImageError={handleImageError}
        announcement="Coming Soon"
      />
    </Container>
  );
};

Header.propTypes = {
  albumCover: PropTypes.string,
  logo: PropTypes.string,
  logoMobile: PropTypes.string,
  title: PropTypes.string,
  onImageError: PropTypes.func,
  announcement: PropTypes.string,
  albumTitle: PropTypes.string
};

export default Header;

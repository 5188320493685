import React from "react";

// Base song data without lyrics
export const songs = [
  {
    title: "Where Were You",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118752/chaoticorder-globallightingproject/1-where-were-you_ufda2c.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118300/chaoticorder-globallightingproject/1-WHERE_WERE_YOU_wgoc56.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413985/chaoticorder-globallightingproject/images-noLyrics/1-wherewereyou_eu9iho.jpg",
  },
  {
    title: "Wake Up",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118752/chaoticorder-globallightingproject/2-wake-up_h0tc9z.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118296/chaoticorder-globallightingproject/2-WAKE_UP_nnmu69.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413979/chaoticorder-globallightingproject/images-noLyrics/2-wakeup_owuj0d.jpg",
  },
  {
    title: "Tears",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118752/chaoticorder-globallightingproject/3-Tears_fym2g1.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118296/chaoticorder-globallightingproject/3-TEARS_lnpihr.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413991/chaoticorder-globallightingproject/images-noLyrics/3-tears_w3nfbk.jpg",
  },
  {
    title: "Dirty Mine",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118753/chaoticorder-globallightingproject/4-Dirty-Mine_g35szv.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118307/chaoticorder-globallightingproject/4-DIRTY_MINE_rjsmiu.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413986/chaoticorder-globallightingproject/images-noLyrics/4-dirtymine_k9deu0.jpg",
  },
  {
    title: "Primitive Man",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118753/chaoticorder-globallightingproject/5-Primitive-Man_gfmwue.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118299/chaoticorder-globallightingproject/5-PRIMITVE_MAN_lrsghw.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413997/chaoticorder-globallightingproject/images-noLyrics/5-primitiveman_fe7nt7.jpg",
  },
  {
    title: "Pins and Needles",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118752/chaoticorder-globallightingproject/6-Pins-and-Needles_ry7kvk.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118311/chaoticorder-globallightingproject/6-PINS_AND_NEEDLES_qzmgra.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413987/chaoticorder-globallightingproject/images-noLyrics/6-pinsandneedles_lknttd.jpg",
  },
  {
    title: "Goodbye",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118753/chaoticorder-globallightingproject/7-Goodbye_emp2lc.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118309/chaoticorder-globallightingproject/7-GOODBYE_ym9sl3.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413992/chaoticorder-globallightingproject/images-noLyrics/7-goodbye_kfoxvy.jpg",
  },
  {
    title: "Fooling Myself",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118753/chaoticorder-globallightingproject/8-Foolin-Myself_anc23n.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118307/chaoticorder-globallightingproject/8-FOOLING_MYSELF_fhsolw.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413997/chaoticorder-globallightingproject/images-noLyrics/8-foolinmyself_dm00pi.jpg",
  },
  {
    title: "Breathe",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118753/chaoticorder-globallightingproject/9-Breathe_ojuucg.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118303/chaoticorder-globallightingproject/9-BREATHE_oodelz.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413996/chaoticorder-globallightingproject/images-noLyrics/9-breathe_p1zkoj.jpg",
  },
  {
    title: "I Still Love You",
    singer: "Chaotic Order",
    thumbnail:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1581118753/chaoticorder-globallightingproject/10-I-Still-Love-you_b8nar3.jpg",
    audio:
      "https://res.cloudinary.com/dexdumfqy/video/upload/v1581118309/chaoticorder-globallightingproject/10-I_STILL_LOVE_YOU_hjwisv.mp3",
    picture:
      "https://res.cloudinary.com/dexdumfqy/image/upload/v1589413992/chaoticorder-globallightingproject/images-noLyrics/10-istillloveyou_fjpigw.jpg",
  },
];

export const lyrics = {
  "Where Were You": [
    <div key="where-were-you-lyrics">
      <p>
        There's a time in my life I loathe
        <br />
        I thought I needed you
        <br />
        Every time I think of you
        <br />
        I'd like to be alone
        <br />I see Now
        <br />
        I'm sure I'm gonna make you cry you when you walk on by
      </p>
      <p>
        Get up and fight,
        <br />
        fight for your life
        <br />
        Fight for the cause
        <br />
        you know is right The time has come,
        <br />
        Time to move on
        <br />
        I'm gonna live to set you free.
      </p>
      <p>
        Where were you,
        <br />
        this fight for life
        <br />
        A place in time yeah you know is right
        <br />
        Where are you in this place in time
        <br />
        A place in time yeah, you know is right
        <br />
        Get up and fight, fight for your life
        <br />
        Fight for the cause you know is right
        <br />
        The time has come,
        <br />
        Time to move on
        <br />
        I'm gonna live to set you free
      </p>
      <p>
        There's a time in my life I know
        <br />
        That need to deal with you
        <br />
        Every time I think of you I'd like to be alone
      </p>
      <p>
        You're fooling yourself with your alibis
        <br />
        Now I know why I said goodbye can see it's time to be free
        <br />
        All those lies you told to me
        <br />
        Get up and fight fight for your life
        <br />
        Fight for the cause you know is right The time has come,
        <br />
        Time to move on I'm gonna live to set you free
        <br />
        Where were you, this fight for life
        <br />
        A place in time yeah, you know is right
        <br />
        Where are you in this place in time
        <br />
        A place in time yeah, you know is right
        <br />
        Get up and fight, fight for your life
        <br />
        Fight for the cause you know is right
      </p>
      <p>
        The time has come,
        <br />
        Time to move on
        <br />
        I'm gonna live to set you free
      </p>
    </div>
  ],
  "Wake Up": [
    <div key="wake-up-lyrics">
      <p>
        The heavy metal nightmares,
        <br />
        Spoils all your dreams,
        <br />
        Eternity and darkness,
        <br />
        Trapped inside your skull
        <br />
        Your life weighs upon you,
        <br />
        it's breathing down your neck
        <br />
        There's a vessel waiting to take away your soul
      </p>
      <p>
        Nothing leads to nowhere,
        <br />
        No such thing as black
        <br />
        Tearing through the threshold,
        <br />
        spinning in your bed
      </p>
      <p>
        Wake up - it's inside your head
        <br />
        Wake up before you're dead
      </p>
      <p>
        A little easy money,
        <br />
        a whole lot of greed
        <br />
        It's mixed in a tall glass,
        <br />
        is it what you need
        <br />
        The shadows are alive now, behind every door
        <br />
        Looking over your shoulder,
        <br />
        you're running ever more
      </p>
      <p>
        Nothing leads to nowhere,
        <br />
        No such thing as black
        <br />
        Tearing through the threshold,
        <br />
        spinning in your bed
      </p>
      <p>
        Wake up - it's inside your head
        <br />
        Wake up - before you're dead
      </p>
      <p>Still time to change, all your dirty deeds</p>
      <p>
        You look around, for a better way
        <br />
        Don't be a fool, change the rules
        <br />
        Look - deep inside, for what you can do
      </p>
      <p>
        Wake up, it's coming for you
        <br />
        Wake up, time to save your soul
        <br />
        Wake up, still time to change
        <br />
        Wake up, deep inside of you
      </p>
    </div>
  ],
  Tears: [
    <div key="tears-lyrics">
      <p>
        Another exhausting day,
        <br />
        there's no time to play
        <br />
        Never ending debts and all those bills to pay
      </p>
      <p>
        Can't you see, I've got to get away
        <br />
        I've got I call your phone,
        <br />
        I love to hear you say I want to be with you,
        <br />
        oh I love you so
      </p>
      <p>
        World so heavy, I can't hold it up
        <br />
        I feel so weak,
        <br />
        I can hardly breathe
        <br />
        It's time to go,
        <br />
        I've got to get away
        <br />
        I'll never come back to this place again
      </p>
      <p>
        And she said Dream with me,
        <br />
        dream with me and see Close your eyes,
        <br />
        float across the sea
        <br />
        Sure to be,
        <br />
        you will soon be free
      </p>
      <p>
        Friday comes,
        <br />
        never soon enough
        <br />
        Celebrating friends never seem to end
      </p>
      <p>
        I Call your phone,
        <br />
        you have great news
        <br />
        She said Baby in me,
        <br />
        changes everything
      </p>
      <p>
        And she said
        <br />
        Dream with me,
        <br />
        dream with me and see
        <br />
        Close your eyes,
        <br />
        float across the sea
        <br />
        Sure to be, you will soon be free
      </p>
      <p>
        With my friends In the car,
        <br />
        Go faster and faster
        <br />
        I heard them laugh,
        <br />
        I Didn't want to hit those trees
        <br />
        1 Should have went straight home,
        <br />
        I'm now in jail
        <br />
        So many years - So many tears
      </p>
      <p>
        Dream with me,
        <br />
        dream with me and see
        <br />
        Close your eyes,
        <br />
        float across the sea
        <br />
        Sure to be, you will soon be free
      </p>
      <p>
        Oh we love you so
        <br />
        Baby said hello
        <br />
        Yes he's walking now
        <br />
        Soon you will be free
      </p>
    </div>
  ],
  "Dirty Mine": [
    <div key="dirty-mine-lyrics">
      <p>
        She works so hard, all week long
        <br />
        She's always up and on her feet
      </p>
      <p>
        She loves to dance, then be romanced
        <br />
        She's feeling good and so divine
      </p>
      <p>
        She sips into a good wine buzz
        <br />
        She moves so fine, I lose my mind
      </p>
      <p>
        Both knowing that time has come
        <br />
        For us to be one on one
      </p>
      <p>
        We get behind closed doors
        <br />
        Our fantasies they do come true
        <br />
        She's my lady, she's my lover
        <br />
        She's my dirty mine
        <br />
        oh so fine on my mind
        <br />
        Girl yeah yeah
        <br />
        Whoo a whoo
      </p>
      <p>
        She walks on by
        <br />
        Can't help but notice
        <br />
        Her friendly smile is never out of place
      </p>
      <p>
        She moves so fine,
        <br />
        she's on my mind
        <br />
        My baby... oh, she's back for more
      </p>
      <p>
        She likes Heavy Metal,
        <br />
        Country music too
        <br />
        It's Country Metal with all her curves
      </p>
      <p>
        We get behind closed doors
        <br />
        Our fantasies they do come true
      </p>
      <p>
        She's my lady,
        <br />
        she's my lover
        <br />
        She's my, dirty mine baby
        <br />
        Oh so fine oh my my my
      </p>
      <p>Giddy up</p>
      <p>
        She works so hard, all week long
        <br />
        She's always up and on her feet
      </p>
      <p>
        She's my lady, she's my lover
        <br />
        She's my dirty mine baby
        <br />
        So fine all mine mine yeah yeah yeah
      </p>
      <p>
        Ohooh ooooh
        <br />
        Yeah yeah yeah giddy up and now
        <br />
        titty titty
      </p>
      <p>
        See all the ladies there on the dance floor
        <br />
        Looking so hot we couldn't ask for more
      </p>
      <p>
        Whoo a whoo
        <br />
        Whoo a whoo
        <br />
        Whoo a whoo
        <br />
        Dirty mine girl ha ha ha ha
      </p>
      <p>Giddy up</p>
    </div>
  ],
  "Primitive Man": [
    <div key="primitive-man-lyrics">
      <p>Ohh, huah, Ohn, huah Ohh, huah, Ohh, huah</p>
      <p>
        I climb the mountains and I live in a cave
        <br />
        I search the valley for what I crave
        <br />
        Sweet scent of woman penetrates my brain
        <br />
        This primal love is driving me insane
      </p>
      <p>
        Primitive man primitive man so primitive
        <br />
        Primitive man primitive man so primitive
      </p>
      <p>
        Ohh, huah, Ohh, huah
        <br />
        I tracked her scent to the watering hole
        <br />
        Where she lay wet naked, so beautiful
      </p>
      <p>
        I drag her back to my cave and then
        <br />
        Showed her my club
        <br />
        She said you're so primitive
      </p>
      <p>
        As they see us fly through the sky
        <br />
        She hears the calls and knowing why
        <br />
        The forces move her to the light
        <br />
        In her body grows intelligent life
        <br />
        She's alien desire
      </p>
      <p>
        Primitive man primitive man so primitive
        <br />
        Primitive man primitive man so primitive
        <br />
        Primitive man primitive man so primitive
        <br />
        Primitive man primitive man so primitive
      </p>
      <p>
        I paint your picture in blood on the wall
        <br />
        On a sabretooth rug we love until dawn
        <br />
        Then I slay a big t-rex, stoke the fire
        <br />
        As her eyes start to glow
      </p>
      <p>
        As They see us l through the sky
        <br />
        She hears the calls and knowing why
        <br />
        The forces move her to the light,
        <br />
        In the body grows intelligent Me
        <br />
        She's alien desire
      </p>
      <p>
        Primitive man primitive man so primitive
        <br />
        Primitive man primitive man so primitive
        <br />
        Primitive man primitive man so primitive
        <br />
        Primitive man primitive man so primitive{" "}
      </p>
    </div>
  ],
  "Pins and Needles": [
    <div key="pins-and-needles-lyrics">
      <p>
        It's a long and lonely road
        <br />
        This unforgiven world we're sold
        <br />
        A life dance on a razors edge
        <br />
        We try so hard every day
      </p>
      <p>
        And it's only when I think of you
        <br />
        That gets me through these desperate times
        <br />
        Yeah yeah
        <br />
        Pins and needles,
        <br />
        Ho no Pins and needles,
        <br />
        Ho no
      </p>
      <p>
        And I know, we're searching for our way back
        <br />
        Though I can hardly breathe
        <br />
        Pins and needles
        <br />
        Piercing, breaking, tearing me apart
      </p>
      <p>
        And here we are my friend
        <br />
        A stabbing knife at my back again
        <br />
        They say tomorrow's another day
        <br />
        How much more can I take
        <br />
        Cause only when I think about you
        <br />
        Can I get through theses desperate times
        <br />
        And only when I dream about you
        <br />
        Can I get through
      </p>
      <p>
        The pins and needles,
        <br />
        Ho no Pins and needles,
        <br />
        Ho no
      </p>
      <p>
        And I know,
        <br />
        we're searching for our way back
        <br />
        Though I can hardly breathe
        <br />
        Pins and needles
        <br />
        Piercing, breaking, tearing me apart
      </p>
      <p>
        How much more can I take
        <br />
        How much more can I take
      </p>
      <p>
        Ho no Pins and needles,
        <br />
        Ho no Pins and needles
      </p>
      <p>And I know, we're searching for our way back</p>
      <p>
        Pins and needles
        <br />
        Piercing, breaking, tearing me apart
        <br />
        Though I can hardly breathe
        <br />
        Tearing me apart{" "}
      </p>
    </div>
  ],
  Goodbye: [
    <div key="goodbye-lyrics">
      <p>
        An uncontrollable time in life
        <br />
        Something more I should have said
        <br />
        Something more i'd say
      </p>
      <p>
        I'd say Rain on my window pain
        <br />
        The sky is falling down
      </p>
      <p>
        Don't know what to do
        <br />
        Don't know what to say
        <br />
        All I can do is pray
        <br />
        To help me find my way
        <br />
        Get me through this PAIN
      </p>
      <p>
        How our lives they used to be
        <br />
        And don't forget the best of days
        <br />
        Now it's time to say
      </p>
      <p>
        Rain on my window pain
        <br />
        The sky is falling down
      </p>
      <p>
        Don't know what to do
        <br />
        Don't know what to say anymore
        <br />
        All I can do is pray
        <br />
        To help me find my way
        <br />
        Get me through this PAIN
      </p>
      <p>Soon the pain will go away</p>
      <p>
        Rain on my window pain
        <br />
        The sky is falling down
      </p>
      <p>
        Don't know what to do
        <br />
        Don't know what to say anymore
        <br />
        All I can do is pray
        <br />
        To help me find my way
        <br />
        And now it's time to say
      </p>
      <p>Goodbye </p>
    </div>
  ],
  "Fooling Myself": [
    <div key="fooling-myself-lyrics">
      <p>
        Looking down this trail of life,
        <br />
        with no way to go
        <br />
        I look in my mind,
        <br />
        I'm dreaming again
      </p>
      <p>
        Ouoo0o
        <br />
        Fooling myself, fooling myself again
        <br />
        I'm fooling myself,
        <br />
        for thinking I can
      </p>
      <p>
        Swimming through my body,
        <br />
        I come across my soul
        <br />
        Still inside my head,
        <br />
        my one and only friend
      </p>
      <p>
        And when I think of colors,
        <br />
        they all turn to red
      </p>
      <p>Ouuuooooo</p>
      <p>
        Fooling myself,
        <br />
        fooling myself again
        <br />
        I'm fooling myself,
        <br />
        for thinking I can
      </p>
      <p>
        For thinking I can
        <br />
        For thinking I can?{" "}
      </p>
    </div>
  ],
  Breathe: [
    <div key="breathe-lyrics">
      <div className="nolyrics">
        <p>[ Instrumentals ]</p>
      </div>
    </div>
  ],
  "I Still Love You": [
    <div key="i-still-love-you-lyrics">
      <p>
        Flying from San Diego Bay, Maui Island a kiss away
        <br />
        Such a sight a sight to see,
        <br />
        the eye candy of Lahaina way
        <br />
        Take to Front Street 393,
        <br />
        see the beach Polanui
      </p>
      <p>
        "Meet the Lindsey's and you'll see , The place of love flowing in the
        breeze
      </p>
      <p>
        I left the mainland to find my way Now I know what I need to say the
        time is right it will be ok
      </p>
      <p>I Still love you And I need you</p>
      <p>I Love you And I Miss you</p>
      <p>Oooo, oooo, oooo, oooo Oooo, oooo, oooo, oooo</p>
      <p>
        Walking down on to the beach A pink sun setting through the cloud I feel
        the sand melting at my feet Sipping on this cool blue drink
      </p>
      <p>
        Palm trees whisper in the breeze The misty ocean it sings to me I've
        never felt so warm and free can stay and never leave
      </p>
      <p>
        I left the mainland to find my way Now I know what I need to say The
        time is right it will be ok
      </p>
      <p>I Still love you And I need you love you And I miss you</p>
      <p>Oooo, oooo, oooo, oooo Oooo, oooo, oooo, oooo </p>
    </div>
  ],
};

// Helper function to get songs with lyrics
export const getSongsWithLyrics = () => {
  return songs.map((song) => ({
    ...song,
    lyric: lyrics[song.title] || [],
  }));
};

// For backwards compatibility
export const songsDesktop = getSongsWithLyrics();
export const songsMobile = songs;

import React from 'react';
import PropTypes from 'prop-types';
//import { Row, Col } from "reactstrap";

const AlbumDisplay = ({ 
  albumCover, 
  logo, 
  logoMobile, 
  title,
  announcement,
  onImageError,
  style,
  className
}) => (
  <div className="mainrow">
    {albumCover && (
      <div className={`albumpromo ${className || ''}`}>
        <img 
          loading="eager"
          className="albumpromo" 
          src={albumCover} 
          alt={title ? `${title} Album Cover` : 'Album Cover'}
          onError={onImageError}
          style={style}
        />
      </div>
    )}
    {logoMobile && logo && (
    <div className="albumlogo">
      {logo && (
        <img 
          loading="eager"
          className="albumlogo" 
          src={logo} 
          alt="Band Logo"
          onError={onImageError}
        />
      )}
      {logoMobile && (
        <img
          loading="eager"
          className="albumlogoMobile"
          src={logoMobile}
          alt="Band Logo Mobile"
          style={{ display: "none" }}
          onError={onImageError}
        />
      )}
      {title && <p className="albumlogo">{title}</p>}
      {announcement && <p className='comingsoon'>{announcement}</p>}
    </div>
    )}
  </div>
);

AlbumDisplay.propTypes = {
  albumCover: PropTypes.string,
  logo: PropTypes.string,
  logoMobile: PropTypes.string,
  title: PropTypes.string,
  announcement: PropTypes.string,
  onImageError: PropTypes.func
};

export default AlbumDisplay;

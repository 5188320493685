import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import Modal from 'react-awesome-modal';
import ReactWebMediaPlayer from "react-web-media-player";

import "../../css/lyricCrawl.css";

const DesktopModal = ({ isOpen, onClose, song }) => (
  <Modal
    visible={isOpen}
    width="1000"
    height="800"
    effect="fadeInUp"
    onClickAway={onClose}
    style={{ padding: 0, zIndex: 1000 }}
  >
    <div style={{ display: "flex" }}>
      <div
        style={{
          backgroundImage: `url(${song.picture})`,
          height: "800px",
          width: "1000px",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ verticalAlign: "middle" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="lyricsCards">
              {song.lyric}
            </div>
            <FaAngleDown
              className="angledown"
              focusable="false"
              aria-hidden="false"
            />
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            left: "77%",
            top: "50%",
          }}
        >
          <ReactWebMediaPlayer
            width={200}
            height={200}
            title={song.title}
            thumbnail={song.thumbnail}
            audio={song.audio}
            vinyl={{
              img: song.thumbnail,
              rpm: 15,
            }}
            style={{
              backgroundColor: "transparent",
              background: "transparent",
              boxShadow: "white 1px 2px 15px",
            }}
          />
        </div>
      </div>
    </div>
  </Modal>
);

const MobileModal = ({ isOpen, onClose, song }) => (
  <Modal
    visible={isOpen}
    width="275"
    height="275"
    effect="fadeInUp"
    onClickAway={onClose}
    style={{ zIndex: 1000 }}
  >
    <div style={{ display: "flex" }}>
      <div style={{ position: "absolute" }}>
        <ReactWebMediaPlayer
          width={275}
          height={275}
          title={song.title}
          thumbnail={song.thumbnail}
          audio={song.audio}
        />
      </div>
    </div>
  </Modal>
);

const LyricsModal = ({ isOpen, onClose, song }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!song) return null;

  return windowWidth <= 980 ? (
    <MobileModal isOpen={isOpen} onClose={onClose} song={song} />
  ) : (
    <DesktopModal isOpen={isOpen} onClose={onClose} song={song} />
  );
};

export default LyricsModal;

// src/components/common/AlbumContent.js
import React from "react";
import SongCard from "./SongCard";
import LyricsModal from "./LyricsModal";

const AlbumContent = ({ songs, rowSize = 5 }) => {
  const [modalState, setModalState] = React.useState({
    isOpen: false,
    activeSong: null
  });

  const rows = [];
  for (let i = 0; i < songs.length; i += rowSize) {
    rows.push(songs.slice(i, i + rowSize));
  }

  const handleCardClick = (song) => {
    setModalState({
      isOpen: true,
      activeSong: song
    });
  };

  const closeModal = () => {
    setModalState({
      isOpen: false,
      activeSong: null
    });
  };

  return (
    <>
      <LyricsModal 
        isOpen={modalState.isOpen}
        onClose={closeModal}
        song={modalState.activeSong}
      />
      
      {rows.map((row, index) => (
        <div key={index} className="ftpsongs">
          {row.map((song) => (
            <SongCard
              key={song.title}
              song={song}
              onClick={() => handleCardClick(song)}
            />
          ))}
        </div>
      ))}
    </>
  );
};

export default AlbumContent;

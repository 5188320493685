import React from "react";
import "./s2FeedThePeople.css";
import Container from "../common/container";
//import { Row, Col } from "reactstrap";
import AlbumContent from "../common/AlbumContent";
//import MobileAlbumContent from "../common/MobileAlbumContent";
import TCardMobile from "./tcardMobile";
import { getSongsWithLyrics } from "../../data/songDataFTP";
import AlbumDisplay from "../s1Header/AlbumDisplay";
import Albumcover from "../../images/album-cover2.jpg";

const FeedThePeople = () => {
  const songs = getSongsWithLyrics();
  
  return (
    <Container 
      id="FeedThePeople"
      className="default"
      scrollId="two"
      scrollBehavior="center"
    >
      <div className="songheaderRow">
        <div>
          <h2 className="songheader">Feed The People</h2>
          <p className="songheader">Tracks and Cover Art</p>
        </div>
      </div>
      <div className="albumpromo">
        <AlbumDisplay
          albumCover={Albumcover}
          logo=""
          logoMobile=""
          title=""
          style={{ marginLeft: "auto", marginRight: "auto" }}
        />
      </div>

      <div className="nomobile">
        <AlbumContent songs={songs} />
      </div>

      <div className="mobile-slider">
        <div className="mobile-slider">
        {/*<MobileAlbumContent songs={songs} /> */}
        <TCardMobile  />
        </div>
      </div>
    </Container>
  );
};

export default FeedThePeople;

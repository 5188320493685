import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
//import Discography from './discography';
//import ScrollToTopRoute from './components/scrolltoTop'

 
   
const App = () => (
  <BrowserRouter>
            <Switch>
             <Route path="/" component={Home} exact/>
             {/*<ScrollToTopRoute path="/discography" component={Discography}/> 
             // The Discography component is temporarily commented out */}
            <Route component={Error}/>
           </Switch>
      </BrowserRouter>
    )


export default App;

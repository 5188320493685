// src/components/common/MobileAlbumContent.js
import React from "react";
import Slider from "react-slick";
import SongCard from "./SongCard";
import LyricsModal from "./LyricsModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderSettings = {
  className: "",
  initialSlide: 0,
  infinite: true,
  centerPadding: "50px",
  slidesToShow: 5,
  swipe: true,
  swipeToSlide: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        className: "",
        initialSlide: 0,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 3,
        swipe: true,
        swipeToSlide: true,
        centerMode: false,
      },
    },
    {
      breakpoint: 736,
      settings: {
        className: "",
        initialSlide: 0,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 3,
        swipe: true,
        swipeToSlide: true,
        centerMode: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        className: "",
        initialSlide: 0,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 2,
        swipe: true,
        swipeToSlide: true,
        centerMode: false,
      },
    },
    {
      breakpoint: 360,
      settings: {
        className: "",
        initialSlide: 0,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 1.5,
        swipe: true,
        swipeToSlide: true,
        centerMode: false,
      },
    },
  ],
};

const MobileAlbumContent = ({ songs }) => {
  const [modalState, setModalState] = React.useState({
    isOpen: false,
    activeSong: null
  });

  const handleCardClick = (song) => {
    setModalState({ isOpen: true, activeSong: song });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, activeSong: null });
  };

  return (
    <>
      <LyricsModal
        isOpen={modalState.isOpen}
        onClose={closeModal}
        song={modalState.activeSong}
      />
      
      <Slider {...sliderSettings}>
        {songs.map((song) => (
          <div key={song.title}>
            <SongCard
              song={song}
              onClick={() => handleCardClick(song)}
            />
          </div>
        ))}
      </Slider>
    </>
  );
};

export default MobileAlbumContent;

import React from "react";
import Container from '../common/container';
import AlbumContent from "../common/AlbumContent";
import MobileAlbumContent from "../common/MobileAlbumContent";
import { getSongsWithLyrics } from "../../data/songDataGLP";
import "./s4GlobalLightingProject.css";
import Glplogo from "../../images/song-art/global-lighting-album/co-oldlogo-fs8.png";

const GlobalLightingProject = () => {
  const songs = getSongsWithLyrics();

  return (
    <Container
      id="GlobalLightingProject"
      scrollId="four"
      scrollBehavior="center"
      className="default"
    >
      <div className="glplogo">
        
        <img className="glplogo" src={Glplogo} alt="Global Lighting Project Logo" />
       
      </div>

        <div className="nomobile">
          <AlbumContent songs={songs} />
        </div>

        <div className="mobile-slider">
          <MobileAlbumContent songs={songs} />
        </div>
    </Container>
  );
};

export default GlobalLightingProject;

import React from 'react';

const Container = ({ id, className, children, scrollId, scrollBehavior }) => (

  <div
    id={id}
    className={`container full screen ${className}`}
    data-scroll-id={scrollId}
    data-scroll-behavior={scrollBehavior}
  >
    <div className="inner">{children}</div>
  </div>

);

export default Container;
